import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import WalletIcon from "../../../../assets/svg/WalletIcon";
import SearchIcon from "../../../../assets/svg/SearchIcon";
import CalendarIcon from "../../../../assets/svg/CalendarIcon";
import { getAccountStatement } from "../../../../redux/user/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { dateFormater } from "../../../../utils/dateFormater";
import { myBetsWithFilter } from "../../../../redux/sports/actions";
import { Table } from "react-bootstrap";
import { APP_CONST } from "../../../../config/const";

const AccountStatement = () => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "day").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(Date()).format("YYYY-MM-DD"));
  const { accountStatement } = useSelector((state) => state.user);
  const [filteredData, setFilteredData] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [dataToRender, setDataToRender] = useState([]);

  const betTabs = [
    {
      id: 0,
      tabName: "All Bets",
      value: "all",
    },
    {
      id: 1,
      tabName: "Match Odds Bets",
      value: "match_odds",
    },
    {
      id: 2,
      tabName: "Fancy Bets",
      value: "fancy",
    },
    {
      id: 3,
      tabName: "Open Bets",
      value: "open",
    },
    {
      id: 4,
      tabName: "Settled Bets",
      value: "settled",
    },
  ];

  useEffect(() => {
    getBetsData({
      ...betTabs[activeTab],
      startdate: new Date(fromDate).toISOString(),
      enddate: new Date(toDate).toISOString(),
      type: "a",
    });
  }, [fromDate, toDate]);

  // useEffect(() => {
  //   if (isSearched) {
  //     setDataToRender(filteredData);
  //   }
  // }, [isSearched]);
  const handleSearch = (value) => {
    if (value) {
      setIsSearched(true);
      let filteredData = dataToRender.filter((item) => {
        return item.matchName.indexOf(value) > -1;
      });
      setFilteredData(filteredData);
      // updateData(filteredData);
    } else {
      setIsSearched(false);
    }
  };

  const getBetsData = (data) => {
    setActiveTab(data.id);
    myBetsWithFilter(data).then((res) => {
      setDataToRender(res.data.data.bets);
    });
  };

  return (
    <div className="account-statement ">
      <div className="mybets-tabs-section">
        <ul>
          {betTabs.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  getBetsData({
                    ...item,
                    startdate: new Date(fromDate).toISOString(),
                    enddate: new Date(toDate).toISOString(),
                    type: "b",
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <div>{item.tabName}</div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="row">
        <div className="col-6">
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Text input with dropdown button"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              type="date"
            />
            <Button variant="primary calender-icon">
              <CalendarIcon />
            </Button>
          </InputGroup>
        </div>
        <div className="col-6">
          <InputGroup className="mb-3">
            <Form.Control
              aria-label="Text input with dropdown button"
              value={toDate}
              type="date"
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
            <Button variant="primary calender-icon">
              <CalendarIcon />
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <Button variant="primary" onClick={() => handleSearch()}>
              <SearchIcon />
            </Button> */}
          </InputGroup>
        </div>
      </div>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Match Name</th>
              <th>Selection Type</th>
              <th>Stack</th>
              <th>Odds</th>
              <th>Status</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {!isSearched
              ? dataToRender.length &&
                dataToRender.map((bet, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{bet?.matchName}</td>
                      <td>
                        <span
                          className={
                            bet?.selectionType === APP_CONST.ODDS_TYPE.BACK
                              ? "back"
                              : "lay"
                          }
                        >
                          {bet?.selectionType}
                        </span>
                      </td>
                      <td>{bet?.stack}</td>
                      <td>{bet?.odds}</td>
                      <td>{bet?.status}</td>
                      <td>
                        {moment(bet?.createdAt).format("dddd DD MMM, hh:mm A")}
                      </td>
                    </tr>
                  );
                })
              : filteredData.length &&
                filteredData.map((bet, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{bet?.matchName}</td>
                      <td
                        className={
                          bet?.selectionType === APP_CONST.ODDS_TYPE.BACK
                            ? "back"
                            : "lay"
                        }
                      >
                        {bet?.selectionType}
                      </td>
                      <td>{bet?.stack}</td>
                      <td>{bet?.odds}</td>
                      <td>{bet?.status}</td>
                      <td>
                        {moment(bet?.createdAt).format("dddd DD MMM, hh:mm A")}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
export default AccountStatement;
