import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccountStatement from "../myBets/accountStatement";
import BackIcon from "../../../../assets/svg/BackIcon";
import LeftSideComponents from "../../../components/LeftSideComponents";

const SportsBet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, wallet } = useSelector((state) => state.auth);
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="container ">
          <div className="PageSection">
            <LeftSideComponents />
            <div className="PageRightBar">
              <div className="RightBarHead d-flex justify-content-between">
                <h2>Sports Bet</h2>
                <div className="back-link" onClick={() => navigate(-1)}>
                  Back <BackIcon />
                </div>
              </div>
              <div className="tabs-area">
                <AccountStatement />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SportsBet;
