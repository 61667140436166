export const EvolutionGames = {
  Baccarat: {
    href: "/casino/ezugi/Baccarat",
    code: "106",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/baccarat/casino034.png", alt: "" },
  },
  Blackjack: {
    href: "/casino/ezugi/Blackjack",
    code: "201",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/blackjack/casino039.png", alt: "" },
  },
  lucky7: {
    href: "/casino/ezugi/lucky7",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/01.jpg", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },
  sicbodice01: {
    href: "/casino/ezugi/sicbodice01",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/dice/casino058.png", alt: "" },
  },

  CrazyTime: {
    href: "/casino/evolution/CrazyTime",
    code: "1000148",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DragonTiger1: {
    href: "/casino/evolution/DragonTiger1",
    code: "1000074",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  GonzoTreasureHunt: {
    href: "/casino/evolution/GonzoTreasureHunt",
    code: "1100022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningDice: {
    href: "/casino/evolution/LightningDice",
    code: "1000076",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningRoulette: {
    href: "/casino/evolution/LightningRoulette",
    code: "1000092",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  Megaball: {
    href: "/casino/evolution/Megaball",
    code: "1000141",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  AmericanRoulette: {
    href: "/casino/evolution/AmericanRoulette",
    code: "1000011",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  DoubleBallRoulette: {
    href: "/casino/evolution/DoubleBallRoulette",
    code: "1000084",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InstantRoulette: {
    href: "/casino/evolution/InstantRoulette",
    code: "1000146",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  Craps: {
    href: "/casino/evolution/Craps",
    code: "1000248",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FreebetBlackjack: {
    href: "/casino/evolution/FreebetBlackjack",
    code: "1000066",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  InfiniteBlackjack: {
    href: "/casino/evolution/InfiniteBlackjack",
    code: "1000067",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningBlackjack: {
    href: "/casino/evolution/LightningBlackjack",
    code: "1000358",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBlackjackA: {
    href: "/casino/evolution/SalonPriveBlackjackA",
    code: "1000068",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SalonPriveBlackjackB: {
    href: "/casino/evolution/SalonPriveBlackjackB",
    code: "1000069",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  GoldenWealthBaccarat: {
    href: "/casino/evolution/GoldenWealthBaccarat",
    code: "1000355",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  LightningBaccarat: {
    href: "/casino/evolution/LightningBaccarat",
    code: "1000149",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  DreamCatcher: {
    href: "/casino/evolution/DreamCatcher",
    code: "1000077",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  FootballStudio: {
    href: "/casino/evolution/FootballStudio",
    code: "1000112",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  SpeedBaccaratA: {
    href: "/casino/evolution/SpeedBaccaratA",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  Roulette: {
    href: "/casino/evolution/Roulette",
    code: "1000094",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  SalonPriveRoulette: {
    href: "/casino/evolution/SalonPriveRoulette",
    code: "1000102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  SalonPriveBlackjackC: {
    href: "/casino/evolution/SalonPriveBlackjackC",
    code: "1000070",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },

  SpeedBlackjackD: {
    href: "/casino/evolution/SpeedBlackjackD",
    code: "1000033",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NoCommSpeedBaccaratA: {
    href: "/casino/evolution/NoCommSpeedBaccaratA",
    code: "1000017",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  NoCommSpeedBaccaratB: {
    href: "/casino/evolution/NoCommSpeedBaccaratB",
    code: "1000018",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratC: {
    href: "/casino/evolution/SpeedBaccaratC",
    code: "1000023",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratA: {
    href: "/casino/evolution/SpeedBaccaratA",
    code: "1000021",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratB: {
    href: "/casino/evolution/SpeedBaccaratB",
    code: "1000022",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
  SpeedBaccaratE: {
    href: "/casino/evolution/SpeedBaccaratE",
    code: "1000025",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
  },
};
