import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { useDispatch } from "react-redux";
import LeftSideComponents from "../../../components/LeftSideComponents";

const Depositrequest = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="container ">
          <div className="PageSection">
            <LeftSideComponents />
            <div className="PageRightBar">
              <div className="RightBarHead">
                <h2>Deposit List</h2>
              </div>
              <div className="tabs-area">
                <div className=" table-responsive withdrawlist"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Depositrequest;
