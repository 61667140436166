import React from "react";
import Header from "../../../containers/Header";
import Footer from "../../../containers/Footer";
import { Tab, Tabs } from "react-bootstrap";
import ProfileIcon from "../../../assets/svg/ProfileIcon";

import AllGame from "../../../assets/images/games-icon/All-Game.png";
import PopularGame from "../../../assets/images/games-icon/Popular.png";
import TopGame from "../../../assets/images/games-icon/Top-game.png";
import SpribeGame from "../../../assets/images/games-icon/Spribe.png";
import RouletteGame from "../../../assets/images/games-icon/Roulette.png";
import BlackjackGame from "../../../assets/images/games-icon/Blackjack.png";
import BaccaratGame from "../../../assets/images/games-icon/Baccart.png";

import TopGameIcon from "../../../assets/images/games-icon/tabs-icon/Top-game.png";
import RouletteGameIcon from "../../../assets/images/games-icon/tabs-icon/Roulette.png";
import BlackjackGameIcon from "../../../assets/images/games-icon/tabs-icon/Blackjack.png";
import BaccaratGameIcon from "../../../assets/images/games-icon/tabs-icon/Baccart.png";
import SpribeGameIcon from "../../../assets/images/games-icon/tabs-icon/Spribe.png";
import PopularGameIcon from "../../../assets/images/games-icon/tabs-icon/Popular.png";

import PlayBtn from "../../../assets/images/play-btn.png";

import AmericanRoulette from "../../../assets/images/roulette/casino/American-Roulette.jpg";
import DiamondRoulette from "../../../assets/images/roulette/casino/Diamond-Roulette.jpg";
import DoubleballRoulette from "../../../assets/images/roulette/casino/Double-ball-Roulette.jpg";
import InstantRoulette from "../../../assets/images/roulette/casino/Instant-Roulette.jpg";
import ItalianRoulette from "../../../assets/images/roulette/casino/Italian-Roulette.jpg";
import LightningRoulette from "../../../assets/images/roulette/casino/Lightning-Roulette.jpg";
import NamasteRoulette from "../../../assets/images/roulette/casino/Namaste-Roulette.jpg";
import Oracle360Roulette from "../../../assets/images/roulette/casino/Oracle-360-Roulette.jpg";
import PrestigeRoulette from "../../../assets/images/roulette/casino/Prestige-Auto-Roulette.jpg";
import Roulette1 from "../../../assets/images/roulette/casino/Roulette.jpg";
import RussianRoulette from "../../../assets/images/roulette/casino/Russian-Roulette.jpg";
import SalonPriveRoulette from "../../../assets/images/roulette/casino/Salon-Prive-Roulette.jpg";
import SpeedAutoRoulette from "../../../assets/images/roulette/casino/Speed-Auto-Roulette.jpg";
import AutoRoulette from "../../../assets/images/roulette/casino/Speed-Roulette.jpg";

import Craps from "../../../assets/images/blackjack/casino/Craps.jpg";
import FreebetBlackjack from "../../../assets/images/blackjack/casino/Freebet-Blackjack.jpg";
import GoldBlackjack from "../../../assets/images/blackjack/casino/Gold-Blackjack-4.jpg";
import InfiniteBlackjack from "../../../assets/images/blackjack/casino/Infinite-Blackjack.jpg";
import ItalianBlackjack from "../../../assets/images/blackjack/casino/Italian-Blackjack.jpg";
import LightningBlackjack from "../../../assets/images/blackjack/casino/Lightning-Blackjack.jpg";
import SalonPriveBlackjackA from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-A.jpg";
import SalonPriveBlackjackB from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-B.jpg";
import SalonPriveBlackjackC from "../../../assets/images/blackjack/casino/Salon-Privac-Blackjack-C.jpg";
import SpeedBlackjack from "../../../assets/images/blackjack/casino/Speed-Blackjack-D.jpg";
import TurkishBlackjack from "../../../assets/images/blackjack/casino/Turkish-Blackjack.jpg";
import UnlimitedBlackjack from "../../../assets/images/blackjack/casino/Unlimited-Blackjack.jpg";
import VipBlackjack2 from "../../../assets/images/blackjack/casino/VIP-Blackjack-2.jpg";
import VipDiamondBlackjack from "../../../assets/images/blackjack/casino/VIP-Diamond-Blackjack.jpg";

import CasinoMarinaBaccarat1 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-1.jpg";
import CasinoMarinaBaccarat2 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-2.jpg";
import CasinoMarinaBaccarat3 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-3.jpg";
import CasinoMarinaBaccarat4 from "../../../assets/images/baccarat/casino/Casino-Marina-Baccarat-4.jpg";
import NoCommBaccarat from "../../../assets/images/baccarat/casino/No-Commission-Baccarat.jpg";
import NoCommSpeedBaccaratA from "../../../assets/images/baccarat/casino/No-Commission-Speed-Baccarat-A.jpg";
import NoCommSpeedBaccaratB from "../../../assets/images/baccarat/casino/No-Commission-Speed-Baccarat-B.jpg";
import RNGBaccarat from "../../../assets/images/baccarat/casino/RNG-Baccarat.jpg";
import RNGBaccarat2020 from "../../../assets/images/baccarat/casino/RNG-Baccarat-2020.jpg";
import SalonBaccaratC from "../../../assets/images/baccarat/casino/Speed-Baccarat-C.jpg";
import SpeedBaccarartB from "../../../assets/images/baccarat/casino/Speed-Baccarat-B.jpg";
import SpeedBaccarartC from "../../../assets/images/baccarat/casino/Speed-Baccarat-C.jpg";
import SpeedBaccarartE from "../../../assets/images/baccarat/casino/Speed-Baccarat-E.jpg";
import GoldenWealthBaccarat from "../../../assets/images/baccarat/casino/Golden-Wealth-Baccarat.jpg";
import LightningBaccarat from "../../../assets/images/baccarat/casino/Lightning-Baccarat.jpg";
import SpeedBaccaratA from "../../../assets/images/baccarat/casino/Speed-Baccarat-A.jpg";
import SpeedCricketBaccarat from "../../../assets/images/baccarat/casino/Speed-Cricket-Baccarat.jpg";
import VIPFortuneBaccarart from "../../../assets/images/baccarat/casino/VIP-Fortune-Baccarat.jpg";
import FiestBaccarat from "../../../assets/images/baccarat/casino/Fiesta-Baccarat.jpg";
import FortuneBaccarat from "../../../assets/images/baccarat/casino/Fortune-Baccarat.jpg";

import AviatorSpribe from "../../../assets/images/spribe/casino/Aviator.jpg";
import MinesSpribe from "../../../assets/images/spribe/casino/mines.jpg";
import DiceSpribe from "../../../assets/images/spribe/casino/dice.jpg";
import GoalSpribe from "../../../assets/images/spribe/casino/Goal.jpg";
import HiloSpribe from "../../../assets/images/spribe/casino/Hilo.jpg";
import PlinkoSpribe from "../../../assets/images/spribe/casino/Plinko.jpg";
import MiniRouletteSpribe from "../../../assets/images/spribe/casino/Mini-roulette.jpg";

import Cards32 from "../../../assets/images/top-game/casino/32-cards.jpg";
import AndarBahar from "../../../assets/images/top-game/casino/andar-bahar.jpg";
import Baccarat from "../../../assets/images/top-game/casino/baccarat.jpg";
import BetonteenPatti from "../../../assets/images/top-game/casino/bet-on-teen-patti.jpg";
import CasinoHoldem from "../../../assets/images/top-game/casino/casino-holdem.jpg";
import CrazyTime from "../../../assets/images/top-game/casino/crazy-time.jpg";
import CricketWar from "../../../assets/images/top-game/casino/Cricket-war.jpg";
import DragonTiger from "../../../assets/images/top-game/casino/dragon-tiger.jpg";
import DreamCatcher from "../../../assets/images/top-game/casino/dream-catcher.jpg";
import FootballStudio from "../../../assets/images/top-game/casino/football-studio.jpg";
import AutoRoulette1 from "../../../assets/images/top-game/casino/auto-roulette.jpg";

import GonzoHunt from "../../../assets/images/popular/casino/gonzo-treasure-hunt.jpg";
import LightDice from "../../../assets/images/popular/casino/lightning-dice.jpg";
import Lucky7 from "../../../assets/images/popular/casino/lucky-7.jpg";
import Megaball from "../../../assets/images/popular/casino/mega-ball.jpg";
import OnedayTeenPatti from "../../../assets/images/popular/casino/one-day-teen-patti.jpg";
import Sicbo from "../../../assets/images/popular/casino/sic-bo.jpg";
import TeenPatti3Card from "../../../assets/images/popular/casino/teen-patti-3-card.jpg";
import UltimateRoulette from "../../../assets/images/popular/casino/ultimate-roulette.jpg";
import { t } from "i18next";

const Casino = () => {
  return (
    <div>
      <div>
        <Header />
      </div>

      <div className="CasinoSecton">
        <Tabs defaultActiveKey="second">
          <Tab
            eventKey="first"
            title={
              <span>
                {" "}
                <img src={AllGame} />
                All Games
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={TopGameIcon} />
                <span>Top Games</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AutoRoulette1} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Cards32} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={AndarBahar} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={AutoRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Baccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BetonteenPatti} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoHoldem} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CrazyTime} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="games-section">
              <div className="game-heading">
                <img src={PopularGameIcon} />
                <span>{t("popular_games")}</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={GonzoHunt} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightDice} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Lucky7} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Megaball} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NamasteRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                {/* <li>
                  <a href="/sign-in">
                    <img src={OnedayTeenPatti} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li> */}
                <li>
                  <a href="/sign-in">
                    <img src={Sicbo} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="games-section">
              <div className="game-heading">
                <img src={SpribeGameIcon} />
                <span>Spribe</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AviatorSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DiceSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoalSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={HiloSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={PlinkoSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={MiniRouletteSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={MinesSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="games-section">
              <div className="game-heading">
                <img src={RouletteGameIcon} />
                <span>Roulette</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AmericanRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DiamondRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DoubleballRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={InstantRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={ItalianRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NamasteRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Oracle360Roulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="games-section">
              <div className="game-heading">
                <img src={BlackjackGameIcon} />
                <span>Blackjack</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={Craps} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FreebetBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoldBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={InfiniteBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={ItalianBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveBlackjackA} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveBlackjackB} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div className="games-section">
              <div className="game-heading">
                <img src={BaccaratGameIcon} />
                <span>Baccarat</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat1} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat3} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat4} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FiestBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FortuneBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoldenWealthBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="second"
            title={
              <span>
                {" "}
                <img src={TopGame} />
                Top
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={TopGameIcon} />
                <span>Top Games</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AutoRoulette1} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Cards32} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={AndarBahar} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={AutoRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Baccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={BetonteenPatti} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoHoldem} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CrazyTime} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CricketWar} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DragonTiger} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DreamCatcher} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FootballStudio} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FortuneBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="third"
            title={
              <span>
                {" "}
                <img src={PopularGame} />
                Popular
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={PopularGameIcon} />
                <span>{t("popular_games")}</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={GonzoHunt} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightDice} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Lucky7} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Megaball} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NamasteRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>

                <li>
                  <a href="/sign-in">
                    <img src={Sicbo} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBaccaratA} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={TeenPatti3Card} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={UltimateRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="fourth"
            title={
              <span>
                {" "}
                <img src={SpribeGame} />
                Spribe{" "}
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={SpribeGameIcon} />
                <span>Spribe</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AviatorSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DiceSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoalSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={HiloSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={PlinkoSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={MiniRouletteSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={MinesSpribe} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="fifth"
            title={
              <span>
                {" "}
                <img src={RouletteGame} />
                Roulette{" "}
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={RouletteGameIcon} />
                <span>Roulette</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={AmericanRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DiamondRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={DoubleballRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={InstantRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={ItalianRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NamasteRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Oracle360Roulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={PrestigeRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={Roulette1} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={RussianRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedAutoRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={AutoRoulette} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="sixth"
            title={
              <span>
                {" "}
                <img src={BlackjackGame} />
                Blackjack{" "}
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={BlackjackGameIcon} />
                <span>Blackjack</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={Craps} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FreebetBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoldBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={InfiniteBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={ItalianBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveBlackjackA} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveBlackjackB} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonPriveBlackjackC} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={TurkishBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={UnlimitedBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={VipBlackjack2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={VipDiamondBlackjack} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
          <Tab
            eventKey="seventh"
            title={
              <span>
                {" "}
                <img src={BaccaratGame} />
                Baccarat{" "}
              </span>
            }
          >
            <div className="games-section">
              <div className="game-heading">
                <img src={BaccaratGameIcon} />
                <span>Baccarat</span>
              </div>
              <ul>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat1} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat2} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat3} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={CasinoMarinaBaccarat4} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FiestBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={FortuneBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={GoldenWealthBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={LightningBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NoCommBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NoCommSpeedBaccaratA} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={NoCommSpeedBaccaratB} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={RNGBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={RNGBaccarat2020} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SalonBaccaratC} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBaccaratA} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBaccarartB} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBaccarartC} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedBaccarartE} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={SpeedCricketBaccarat} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
                <li>
                  <a href="/sign-in">
                    <img src={VIPFortuneBaccarart} />
                    <div className="casino_overlay">
                      <img src={PlayBtn} />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Casino;
