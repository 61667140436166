import { APP_DETAILS, BANK_DETAILS, BANNERS } from "./types";

const initialState = {
  appDetails:
    localStorage.getItem("appDetails") !== null
      ? JSON.parse(localStorage.getItem("appDetails"))
      : null,
  bankDetails: null,
  banners:
    localStorage.getItem("banners") !== null
      ? JSON.parse(localStorage.getItem("banners"))
      : null,
};

export default (state = initialState, actions) => {
  const { payload, type } = actions;
  switch (type) {
    case APP_DETAILS:
      localStorage.setItem("appDetails", JSON.stringify(payload));
      return {
        ...state,
        appDetails: payload,
      };
    case BANK_DETAILS:
      return {
        ...state,
        bankDetails: payload,
      };
    case BANNERS:
      payload.sort((a, b) => a.order - b.order);
      let filteredData = [];
      if (Array.isArray(payload)) {
        filteredData = payload.filter(({ order }) => order <= 100);
      } else {
        filteredData = payload;
      }
      localStorage.setItem("banners", JSON.stringify(filteredData));
      return {
        ...state,
        banners: filteredData,
      };

    default:
      return state;
  }
};
